/* eslint-disable jsx-a11y/anchor-is-valid */
"use client";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React, { useEffect, useState } from "react";

import { drawMonogram } from "@/utils/design-elements";
import { formatClassName, getPageName, isInCurrentDirectory } from "@/utils/helpers";

interface NavItem {
  label: string;
  href: string;
  side: "left" | "right"; // Indicates which side the item belongs to
  children?: NavItem[]; // Optional children for dropdowns
}

const navItems: NavItem[] = [
  {
    // children: [
    //   { href: "/donate/uk", label: "United Kingdom", side: "left" },
    //   { href: "/donate/us", label: "United States", side: "left" },
    //   { href: "/donate/global", label: "Global", side: "left" },
    // ],
    href: "/donate/uk",
    label: "Donate",
    side: "right",
  },
  {
    // children: [
    //   { href: "/about/rapid-response-for-refugees", label: "RRR", side: "left" },
    //   { href: "/about/team", label: "Team", side: "left" },
    // ],
    href: "/about",
    label: "About",
    side: "left",
  },
  // {
  //   href: "/past-impact",
  //   label: "Past Impact",
  //   side: "right",
  // },
  // {
  //   href: "/future-impact",
  //   label: "Future Impact",
  //   side: "right",
  // },
];

const renderNavItems = (items: NavItem[], side: "left" | "right"): React.JSX.Element[] => {
  return items
    .filter((item) => item.side === side) // Filter items based on the side
    .map((item) => {
      const [isOpen, setIsOpen] = useState(false);

      const handleMouseEnter = (): void => setIsOpen(true);
      const handleMouseLeave = (): void => setIsOpen(false);

      // Determine if the <ul> should have the "decorate-with-border" class
      const ulClassName =
        item.children && item.children.length >= 3
          ? "top-app-bav-dropdown-nav decorate-with-border"
          : "top-app-bav-dropdown-nav";

      const pathname: string = usePathname() || "";
      const isInDirectory = isInCurrentDirectory(pathname, item.href);

      return (
        <li
          className={formatClassName(getPageName(item.label))}
          key={item.href}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Link
            aria-current={getPageName(pathname) === getPageName(item.href) ? "page" : undefined}
            data-is-in-current-directory={isInDirectory ? "true" : "false"}
            href={item.href}
          >
            {item.label}
          </Link>
          {item.children && (
            <ul className={ulClassName} data-is-open={isOpen ? "true" : "false"}>
              {renderNavItems(item.children, side)}
            </ul>
          )}
        </li>
      );
    });
};

export default function TopAppBar(): React.JSX.Element {
  const pathname: string = usePathname() || "";
  const pageName = getPageName(pathname) === "" ? "home" : getPageName(pathname);
  // console.log(pageName);

  useEffect(() => {
    document.body.setAttribute("data-is-loaded", "true");
  }, []);

  //   useLayoutEffect(() => {
  //     const handleScroll = (): void => {
  //       const threshold = 40; // Adjust the scroll threshold as needed
  //       const isCollapsed = window.scrollY > threshold;
  //       document.body.setAttribute("data-top-app-bar-is-collapsed", String(isCollapsed));
  //     };
  //
  //     // Add the scroll event listener
  //     window.addEventListener("scroll", handleScroll);
  //
  //     // Cleanup the event listener on component unmount
  //     return (): void => {
  //       window.removeEventListener("scroll", handleScroll);
  //     };
  //   }, []);

  return (
    <header data-page-name={pageName}>
      <div className="top-app-bar top-app-bar-with-decoration">
        <div className="top-app-bar-cell top-app-bar-utility-row">
          <ul className="top-app-bar-nav utility-links">
            <li className="investors">
              <a
                className="inline-text-button-link"
                href="https://thefuturefound.com"
                rel="noreferrer"
                target="_blank"
              >
                thefuturefound.com
              </a>
            </li>
          </ul>
        </div>
        <div className="top-app-bar-cell top-app-bar-left">
          <ul className="top-app-bar-nav top-app-bar-nav-primary">
            {renderNavItems(navItems, "left")}
          </ul>
        </div>
        <div className="top-app-bar-cell top-app-bar-brandmark top-app-bar-brandmark-with-wordmark-and-descriptor">
          <Link
            aria-current={getPageName(pathname) === getPageName("/") ? "page" : undefined}
            aria-label="Link to homepage."
            className="symbolmark"
            href="/"
          >
            {drawMonogram({
              size: 128,
            })}
          </Link>
        </div>
        <div className="top-app-bar-cell top-app-bar-right">
          <ul className="top-app-bar-nav top-app-bar-nav-primary align-right">
            {renderNavItems(navItems, "right")}
          </ul>
        </div>
      </div>
    </header>
  );
}
